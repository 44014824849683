@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&family=Montserrat:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,700;1,800&family=Mulish:wght@200;300;400;500;600;700;800;900;1000&display=swap');

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

html {
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;
}

a {
    text-decoration: none;
}

body {
    background-color: #f4f7fa;
}

.disabled {
    pointer-events: none !important;
    cursor: not-allowed !important;
    opacity: 0.75 !important;
    filter: alpha(opacity=75) !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}